<template>
  <van-popup
    v-model="showPicker"
    class="fill-in-wrap-pop"
    position="right"
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap">
      <div class="content fillin-upload-ctn">
        <van-cell title-class="fillin-upload-ctn-title">
          <template #title>
            <span v-if="detail.isRequired == 'Y'" class="color-r m_r_8">*</span>
            <span class="custom-title">{{ detail.name }}</span>
          </template>
          <template #label>
            <div class="dis_flex ali_it_cen">
              <span
                v-if="detail.exampleUrl"
                class="custom-title color-blue m_r_16"
                @click="previewDemo(detail.exampleUrl)"
              >
                <van-icon name="eye-o" />
                示例
              </span>
              <span class="custom-title"
                >({{ acceptTipsFn(detail.attachType) }})</span
              >
            </div>
          </template>
        </van-cell>
        <div class="p_20_32">
          <MultUpload
            v-model="detail.lists"
            multiple
            previewVali="mobile"
            :isDefaultAccept="false"
            :accept="acceptFn(detail.attachType)"
          />
        </div>
      </div>
      <div class="btns">
        <van-button size="small" @click="handleClose">返回</van-button>
        <van-button type="info" size="small" @click="saveFn">保存</van-button>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ImagePreview } from "vant";
import { saveApplyImagesNew } from "@/api";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 需要填的表单内容
    detail: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    MultUpload: () => import("@/common/MultUpload.vue")
  },
  watch: {
    showPicker(v) {
      if (v) {
        try {
          console.log("this.detail :>> ", this.detail);
          this.init();
        } catch (e) {
          console.log("查看报错", e);
        }
      } else {
        this.$emit("update");
      }
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  data() {
    return {
      pageLoading: false
    };
  },
  created() {},
  methods: {
    init() {
      this.getProductConfigs();
    },
    // 产品配置
    async getProductConfigs() {
      try {
        this.pageLoading = true;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    previewDemo(url) {
      ImagePreview({ images: [url], closeable: true });
    },
    async saveFn() {
      try {
        this.pageLoading = true;
        if (this.detail.isRequired == "Y" && !this.detail.lists.length) {
          this.$toast("请上传" + this.detail.name);
          return;
        }
        let materials = [];
        this.detail.lists.map((item) => {
          materials.push({
            detailId: this.detail.detailId,
            financeStage: this.detail.financeStage,
            imageType: this.detail.attachType,
            imageName: item.name,
            imageUrl: item.url
          });
        });
        await saveApplyImagesNew({
          bizNo: this.detail.bizNo,
          detailId: this.detail.detailId,
          images: materials
        });
        this.$emit("update", this.form);

        this.showPicker = false;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    handleClose() {
      // 用户点击关闭图标时触发
      this.showPicker = false;
      this.$nextTick(() => {
        this.showPicker = false;
      });
    },
    acceptFn(fileType) {
      if (fileType == "PIC") {
        return ".jpg, .jpeg, .png";
      } else if (fileType == "VIDEO") {
        return "video/*";
      } else if (fileType == "DOC") {
        return ".pdf,.xlsx,.csv,.xls";
      } else {
        return "image/*,video/*";
      }
    },
    acceptTipsFn(fileType) {
      if (fileType == "PIC") {
        return "请上传.jpg, .jpeg, .png格式文件";
      } else if (fileType == "VIDEO") {
        return "请上传视频格式文件";
      } else if (fileType == "DOC") {
        return "请上传pdf格式文件";
      } else {
        return "image/*,video/*";
      }
    },
    clickPreviewFn(item) {
      this.detailInfo = item || {};
      this.showDetailPicker = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap-pop {
  :deep(.fill-in-wrap) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .head {
      height: 96px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #333;
      border-bottom: 1px solid #efefef;
    }
    .content {
      flex: 1;
      overflow-y: auto;

      .color-r {
        color: red;
      }
      .color-blue {
        color: rgb(24, 144, 255);
      }
    }
    .fillin-upload-ctn-title {
      min-width: 65vw;
    }
    .btns {
      border-top: 1px solid #efefef;
      height: 112px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0 -5px 10px #efefef;
      .van-button {
        min-width: 160px;
      }
    }
  }
}
</style>
